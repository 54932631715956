import React from 'react';
import { Flex, Box, Heading, Text } from '@chakra-ui/react';

const Mission = () => {
  return (
    <Flex 
      justifyContent="center" 
      alignItems="center" 
      backgroundColor="gray.100" 
      minHeight="55vh" 
      py={{ base: "2em", md: "4em" }} // Adjust padding for mobile
    >
      <Box 
        py={{ base: '2em', md: '3em' }} // Adjust padding for mobile
        px={{ base: '2em', md: '3em', lg: '4em' }} // More compact padding for mobile
        width={{ base: '90%', md: '80%', lg: '60%' }} // Slightly reduce width on mobile
        backgroundColor="white" 
        borderRadius="lg" 
        boxShadow="xl"
      >
        <Heading 
          mb={{ base: '1em', md: '1em' }} // Tighter margin for mobile
          textAlign="center" 
          as="h1" 
          fontWeight="600" 
          fontSize={{ base: '28px', md: '36px', lg: '40px' }} // Adjust font size for mobile
          color="red.500"
        >
          Our Mission
        </Heading>

        <Text 
          fontSize={{ base: '15px', md: '17px', lg: '19px' }} // Slightly smaller font for mobile
          lineHeight="1.7" 
          mb={6} 
          color="gray.600" 
          fontWeight="300"
        >
          Each day, thousands of analysts in national security and the private sector are tasked with anticipating political risk and dangerous events.
        </Text>

        <Text 
          fontSize={{ base: '15px', md: '17px', lg: '19px' }} 
          lineHeight="1.7" 
          mb={6} 
          color="gray.600" 
          fontWeight="300"
        >
          An analyst must sift through mountains of data to make recommendations to executives and warfighters — a task rife with human error. Miscalculations can endanger life and property.
        </Text>

        <Text 
          fontSize={{ base: '15px', md: '17px', lg: '19px' }} 
          lineHeight="1.7" 
          mb={6} 
          color="gray.600" 
          fontWeight="300"
        >
          At Helios, we are designing an innovative machine learning platform to mitigate risk and keep your team safe. With state-of-the-art machine learning techniques, we strive to offer real-time forecasts of political violence linked to any malicious group, anywhere in the world.
        </Text>
      </Box>
    </Flex>
  );
};

export default Mission;