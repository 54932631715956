import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Flex } from '@chakra-ui/react';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

const Auth = ({ signupError, loginError, handleLogin, handleSignup }) => {

  const navigate = useNavigate();
  const [authState, setAuthState] = useState('login');

  const handleSwitchAuthState = () => {
    authState === "login" ? setAuthState("signup") : setAuthState("login")
  }

  const handleSubmit = (user) => {
    const onSuccess = () => navigate("/dashboard");
    if (authState === "login") {
      handleLogin(user, onSuccess);
    } else {
      handleSignup(user, onSuccess);
    }
  }

  return (
    <Flex height="85vh" alignItems="center" justifyContent="center">
      {authState === "login" ?
        <LoginForm loginError={loginError} handleSwitchAuthState={handleSwitchAuthState} handleSubmit={handleSubmit} />
        :
        <SignupForm signupError={signupError} handleSwitchAuthState={handleSwitchAuthState} handleSubmit={handleSubmit} />

      }
    </Flex>
  );
};

export default Auth;