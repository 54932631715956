import React, { useState } from 'react';
import {
    Flex,
    Text,
    Button,
    Input,
    FormControl,
    Heading,
    Divider,
    Image,
    Alert,
    AlertIcon,
    useBreakpointValue,
} from '@chakra-ui/react';

const LoginForm = ({ loginError, handleSubmit, handleSwitchAuthState }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const isMobile = useBreakpointValue({ base: true, md: false }); // Determines if it's mobile or desktop

    const onSubmit = (e) => {
        e.preventDefault();
        const user = {
            email,
            password,
        };
        handleSubmit(user);
    };

    const imageHeight = useBreakpointValue({ base: '75px', md: '100px', lg: '125px' });

    return (
        <Flex 
            height={{ base: 'auto', md: '70%', lg: '80%' }} 
            backgroundColor="gray.100" 
            mx={{ base: "1em", md: "6em" }}  // Adjusted for mobile responsiveness
            px={{ base: "1em", md: "2em" }}  // Added padding for mobile view
        >
            <Flex
                direction={{ base: 'column', md: 'row' }}
                bg="white"
                rounded="xl"
                boxShadow="lg"
                alignItems="center"
                width="100%"  // Ensures it takes up full width on mobile
                pb={isMobile && "3em"}
            >
                <Flex
                    width={{ base: '100%', md: '50%' }}
                    borderLeftRadius={{ base: 'none', md: 'lg' }}
                    alignItems="center"
                    textAlign="center"
                    flexDir="column"
                    px={{ base: '1em', md: '2em' }} // Responsive padding
                    mt={isMobile && "1.5em"}
                    mb={isMobile && "2em"}
                >
                    <Image src="Helios.png" alt="Helios Logo" height={imageHeight} />
                    <Text 
                        mt={{ base: "0em", md: "1em"}}
                        color="grey" 
                        fontSize={{ base: '13px', md: '15px', lg: '17px' }} 
                        fontWeight="300"
                    >
                        A machine learning platform to detect political violence and protect your team.
                        Sign in to gain access to the dashboard.
                    </Text>
                </Flex>
                
                {isMobile ? (
                    <Divider orientation="horizontal" display="block" />
                ) : (
                    <Divider orientation="vertical" display="block" />
                )}

                <Flex 
                    width={{ base: '100%', md: '50%' }} 
                    justifyContent="center"
                    px={{ base: '1em', md: '0' }} // Padding for mobile view
                >
                    <form onSubmit={onSubmit} style={{ width: '100%' }}>
                        <Flex flexDirection="column" alignItems="center" width="100%">
                            <Heading 
                                fontSize={{ base: '30px', md: '36px', lg: '40px' }} 
                                fontWeight="600" 
                                color="red.500"
                                mt={isMobile && '0.75em'} 
                                mb={"0.75em"}
                            >
                                Sign In
                            </Heading>

                            {loginError && (
                                <Alert
                                    status="error"
                                    mb={"1em"}
                                    borderRadius={5}
                                    w={{ base: '250px', md: '300px', lg: '400px' }}
                                >
                                    <AlertIcon
                                        mr="1.5"
                                        w={{ base: '10px', md: '12px', lg: '14px' }}
                                        h={{ base: '10px', md: '12px', lg: '14px' }}
                                    />
                                    <Text fontWeight="400" fontSize={{ base: '10px', md: '12px', lg: '14px' }}>
                                        Invalid email or password.
                                    </Text>
                                </Alert>
                            )}

                            <Flex flexDir="column" justifyContent="center">
                                <FormControl isRequired mb={isMobile ? "0.75em" : "1em"}>
                                    <Input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Email"
                                        bg="gray.200"
                                        type="email"
                                        fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                        h={{ base: '35px', md: '40px', lg: '45px' }} // More fluid height
                                        w={{ base: '250px', md: '300px', lg: '400px' }}
                                        pl={"1em"}
                                        borderRadius="5px"
                                    />
                                </FormControl>
                                <FormControl isRequired mb={isMobile ? "0.75em" : "1em"}>
                                    <Input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Password"
                                        bg="gray.200"
                                        type="password"
                                        fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                        h={{ base: '35px', md: '40px', lg: '45px' }} // More fluid height
                                        w={{ base: '250px', md: '300px', lg: '400px' }}
                                        pl={"1em"}
                                        borderRadius="5px"
                                    />
                                </FormControl>
                            </Flex>
                            <Button
                                cursor="pointer"
                                type="submit"
                                bg="red.400"
                                color="white"
                                _hover={{ bg: 'red.600' }}
                                borderRadius="5px"
                                h={{ base: '30px', md: '35px', lg: '40px' }}
                                w={{ base: '250px', md: '300px', lg: '400px' }}
                                fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                fontWeight="bold"
                                mb={isMobile ? "1.5em" : "2em"}
                            >
                                Sign In
                            </Button>
                            <Flex justifyContent="center" flexDir="row">
                                <Text mr={1} fontSize={{ base: '10px', md: '12px', lg: '14px' }} color="gray.600">
                                    Don't have an account?
                                </Text>
                                <Text
                                    _hover={{ color: 'red.600' }}
                                    onClick={handleSwitchAuthState}
                                    fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                    fontWeight="bold"
                                    color="red.400"
                                    cursor="pointer"
                                >
                                    Sign Up Here!
                                </Text>
                            </Flex>
                        </Flex>
                    </form>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default LoginForm;