import React from 'react';
import { Box, Heading, Text, Stack, Icon, Divider, Flex } from '@chakra-ui/react';
import { FaLightbulb, FaCogs, FaChartLine } from 'react-icons/fa';

const About = () => {
  return (
    <Flex 
      justifyContent="center" 
      alignItems="center" 
      backgroundColor="gray.100" 
      minHeight="55vh" 
      py={{ base: "2em", md: "4em" }} // Adjust padding for mobile
    >
      <Box 
        py={{ base: '2em', md: '3em' }} 
        px={{ base: '2em', md: '3em', lg: '4em' }} // More compact padding for mobile
        width={{ base: '90%', md: '80%', lg: '60%' }} // Slightly reduce width on mobile
        backgroundColor="white" 
        borderRadius="lg" 
        boxShadow="xl"
      >
        <Heading 
          mb={{ base: '1em', md: '1em' }} // Tighter margin for mobile
          textAlign="center" 
          as="h1" 
          fontWeight="600" 
          fontSize={{ base: '28px', md: '36px', lg: '40px' }} // Adjust font size for mobile
          color="red.500"
        >
          About Us
        </Heading>

        <Stack spacing={6}> {/* Reduce spacing for mobile */}
          {/* First Section */}
          <Box textAlign={"left"}> {/* Center text on mobile */}
            <Stack direction="row" align="center" justifyContent={'flex-start'} mb={4}>
              <Icon as={FaLightbulb} w={6} h={6} color="yellow.400" />
              <Heading as="h2" fontSize={{ base: '15px', md: '17px', lg: '19px' }} fontWeight="600" color="gray.700">
                A Small Team with Big Ideas
              </Heading>
            </Stack>
            <Text 
              fontSize={{ base: '15px', md: '17px', lg: '19px' }} // Slightly smaller font for mobile
              fontWeight="300" 
              color="gray.600" 
              lineHeight="1.7"
              textAlign={"left"}
            >
              Organizations that adapt to the speed and volume of 21st century data flows will be market leaders and trusted institutions. Those that don’t will be left behind. Helios aims to support teams keen on leveraging data for insight and action.
            </Text>
            <Divider mt={6} />
          </Box>

          {/* Second Section */}
          <Box textAlign={"left"}>
            <Stack direction="row" align="center" justifyContent={'flex-start'} mb={4}>
              <Icon as={FaCogs} w={6} h={6} color="blue.400" />
              <Heading as="h2" fontSize={{ base: '15px', md: '17px', lg: '19px' }} fontWeight="600" color="gray.700">
                Novel Frameworks
              </Heading>
            </Stack>
            <Text 
              fontSize={{ base: '15px', md: '17px', lg: '19px' }} 
              fontWeight="300" 
              color="gray.600" 
              lineHeight="1.7"
              textAlign={"left"}
            >
              At Helios, we are designing innovative machine learning pipelines to predict political violence. We propose novel software frameworks that analyze patterns across the information environment and offer real-time forecasts of dangerous threats at a high geographic resolution.
            </Text>
            <Divider mt={6} />
          </Box>

          {/* Third Section */}
          <Box textAlign={"left"}>
            <Stack direction="row" align="center" justifyContent={'flex-start'} mb={4}>
              <Icon as={FaChartLine} w={6} h={6} color="green.400" />
              <Heading as="h2" fontSize={{ base: '15px', md: '17px', lg: '19px' }} fontWeight="600" color="gray.700">
                Actionable Insights
              </Heading>
            </Stack>
            <Text 
              fontSize={{ base: '15px', md: '17px', lg: '19px' }} 
              fontWeight="300" 
              color="gray.600" 
              lineHeight="1.7"
              textAlign={"left"}
            >
              We designed our baseline models in early 2024 to accurately predict the location and frequency of daily military activity across Ukrainian municipalities. Our framework helps our customers identify and anticipate precise types of threats to their personnel and assets.
            </Text>
          </Box>
        </Stack>
      </Box>
    </Flex>
  );
};

export default About;