import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';

// Function to dynamically load Google Maps script
const loadGoogleMapsScript = (callback) => {
  const existingScript = document.getElementById('googleMaps');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCvbOfhe5SSsa5Br_r7YqzEfV89eUTWwxg&libraries=visualization,places`;
    script.id = 'googleMaps';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  } else if (callback) {
    callback();
  }
};

// Function to generate hexagonal coordinates
const getHexagonCoords = (center, radius) => {
  const angle = Math.PI / 3; // 60 degrees
  const coords = [];

  for (let i = 0; i < 6; i++) {
    const x = center.lng() + radius * Math.cos(angle * i);
    const y = center.lat() + radius * Math.sin(angle * i);
    coords.push({ lat: y, lng: x });
  }

  // Ensure the hexagon is closed by repeating the first coordinate
  coords.push(coords[0]);

  return coords;
};

const HeatMap = ({ heatMapData, day, showHeatMap, country }) => {
  const mapRef = useRef(null);
  const heatmapRef = useRef(null);
  const dataLayerRef = useRef(null); // Ref for hexagon Data layer
  const [map, setMap] = useState(null);

  useEffect(() => {
    loadGoogleMapsScript(() => {
      if (window.google && window.google.maps) {
        const googleMap = new window.google.maps.Map(mapRef.current, {
          center: { lat: 20, lng: 10 }, // Default center
          zoom: 2, // Default zoom for global view
          fullscreenControl: true,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
        });
        setMap(googleMap);
      } else {
        console.error('Google Maps script did not load correctly.');
      }
    });
  }, []);

  // Use effect to handle zooming based on country change
  useEffect(() => {
    if (window.google && window.google.maps && map && country) {
      const geocodeCountry = async (countryName) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: countryName }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
            const { lat, lng } = results[0].geometry.location;
            map.setCenter({ lat: lat(), lng: lng() });
            map.setZoom(5);
          } else {
            console.error('Geocoding failed for country:', countryName);
          }
        });
      };

      if (country !== '') {
        geocodeCountry(country);
      } else {
        map.setCenter({ lat: 20, lng: 10 });
        map.setZoom(2); // Reset to default zoom if no country is provided
      }
    }
  }, [country, map]);

  // Use effect to handle heatmap data and day change (without affecting zoom)
  useEffect(() => {
    if (window.google && window.google.maps && map) {
      const getHeatMapDataFromJSON = () => {
        const dates = Object.keys(heatMapData);
        const selectedDate = dates[day];
        const dataForDate = heatMapData[selectedDate];
        return dataForDate.map(point => ({
          location: new window.google.maps.LatLng(
              parseFloat(point['lat-long'][0]), 
              parseFloat(point['lat-long'][1])
          ),
          weight: parseFloat(point.weight) * 10
        }));
      };

      if (showHeatMap) {
        // Fetch heatmap data
        const data = getHeatMapDataFromJSON();

        // If heatmap already exists, update its data
        if (heatmapRef.current) {
          heatmapRef.current.setData(data);
          heatmapRef.current.setMap(map);
        } else {
          // Create the heatmap layer for the first time
          const heatmap = new window.google.maps.visualization.HeatmapLayer({
            data: data,
            radius: 0.2,
            opacity: 0.85,
            dissipating: false,
            gradient: [
              'rgba(255, 255, 255, 0)',
              'rgba(255, 255, 255, 1)',
              'rgba(255, 200, 0, 1)',
              'rgba(255, 100, 0, 1)',
              'rgba(255, 0, 0, 1)'
            ]
          });

          heatmap.setMap(map);
          heatmapRef.current = heatmap;
        }

        // Draw hexagons
        if (!dataLayerRef.current) {
          const dataLayer = new window.google.maps.Data();
          dataLayer.setMap(map);
          dataLayerRef.current = dataLayer;
        }

        // Remove any previous hexagon features
        dataLayerRef.current.forEach(feature => dataLayerRef.current.remove(feature));

        // Draw new hexagons based on heatmap data
        const hexagonFeatures = data.map(point => {
          const coords = getHexagonCoords(point.location, 0.1); // Adjust size as needed
          return {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [coords.map(coord => [coord.lng, coord.lat])]
            },
            properties: {
              weight: point.weight
            }
          };
        });

        // Add hexagon features to the map
        dataLayerRef.current.addGeoJson({
          type: 'FeatureCollection',
          features: hexagonFeatures
        });

        // Set the style for the hexagons with transparent fill and light gray borders
        dataLayerRef.current.setStyle({
          strokeColor: 'lightgray', // Set the border color to light gray
          strokeWeight: 1,          // Set the thickness of the border
          fillColor: 'red',          // You can choose any color for the fill, but it will be transparent due to fillOpacity
          fillOpacity: 0             // Make the fill completely transparent
        });
      } else {
        // Hide heatmap and hexagons if showHeatMap is false
        if (heatmapRef.current) {
          heatmapRef.current.setMap(null);
        }
        if (dataLayerRef.current) {
          dataLayerRef.current.setMap(null);
        }
      }
    }
  }, [showHeatMap, map, heatMapData, day]);

  return (
    <Box 
      className="custom-map-container" 
      ref={mapRef} 
      height="100%"
      width="100%"
      sx={{ outline: 'none' }} 
    />
  );
};

export default HeatMap;