import React from 'react';
import { Box, Heading, Text, Stack, Divider, Flex, Link, IconButton, useBreakpointValue } from '@chakra-ui/react';
import { FaLinkedin } from 'react-icons/fa';

const teamMembers = [
  {
    name: 'Alexander Casendino',
    title: 'Co-Founder & CEO',
    description: `Alex guides business operations at Helios. In prior work at the Center for Naval Analyses, 
      he supported the federal COVID-19 vaccine response and the Department of Defense’s Global Posture Review. 
      He graduated in 2024 from Yale’s Jackson School of Global Affairs with an M.P.P. concentrating in machine 
      learning and intelligence studies.`,
    linkedIn: 'https://www.linkedin.com/in/alexandercasendino/',
  },
  {
    name: 'Andrew Coli',
    title: 'Co-Founder & CTO',
    description: `Andrew researches and validates data sources used in our predictive models. Prior to Helios, 
      Andrew worked at Amazon as a data scientist. He graduated from Yale University with a B.S. in Statistics 
      and Data Science in 2024.`,
    linkedIn: 'https://www.linkedin.com/in/andrew-coli/',
  },
  {
    name: 'Andy Yang',
    title: 'Machine Learning Engineer',
    description: `Andy leads model development at Helios. Andy draws on several years of applied engineering 
      and research experience, including analyst positions at Citadel Securities and Yale University. Andy 
      graduated from Yale University with a dual B.S./M.A. in Statistics in 2024.`,
    linkedIn: 'https://www.linkedin.com/in/andy-yang-868919166/',
  },
  {
    name: 'Nikhil Ismail',
    title: 'Software Engineer',
    description: `Nikhil leads website development at Helios. Nikhil draws on several years of software engineering and 
      full-stack development experience, including multiple internships at Microsoft and a YC-backed startup. 
      He is currently pursuing a B.S. in Computer Science at Yale University.`,
    linkedIn: 'https://www.linkedin.com/in/nikhil-ismail/',
  },
  {
    name: 'Dylan Oberst',
    title: 'Data Science Intern',
    description: `Dylan develops natural-language processing (NLP) pipelines to predict conflict events from 
      news feeds. In prior positions at AI Bloks and One River Asset Management, Dylan implemented generative 
      AI applications in software systems. He is pursuing a B.S./M.A. in Statistics at Yale University.`,
    linkedIn: 'https://www.linkedin.com/in/dylan-oberst-04420a18b/',
  },
];

const Team = () => {
  const linkedinIcon = useBreakpointValue({ base: '2em', md: '3em', lg: '4em' });

  return (
    <Flex justifyContent="center" alignItems="center" backgroundColor="gray.100" minHeight="60vh" py={{ base: '2em', md: '4em' }}>
      <Box
        py={{ base: '2em', md: '3em' }}
        px={{ base: '2em', md: '4em' }}
        width={{ base: '90%', md: '80%', lg: '60%' }}
        backgroundColor="white"
        borderRadius="lg"
        boxShadow="xl"
      >
        <Heading mb={{ base: '1em', md: '1em' }} textAlign="center" as="h1" fontWeight="600" fontSize={{ base: '28px', md: '36px', lg: '40px' }} color="red.500">
          Our Team
        </Heading>

        <Stack spacing={{ base: 6, md: 8 }}>
          {teamMembers.map((member, index) => (
            <Box key={index} textAlign="left">
              <Flex align="center" mb="0.5em">
                <Heading as="h2" fontSize={{ base: '15px', md: '17px', lg: '19px' }} fontWeight="600" color="gray.800" mr={2}>
                  {member.name}
                </Heading>
                <Link href={member.linkedIn} isExternal>
                  <IconButton
                    aria-label="LinkedIn"
                    icon={<FaLinkedin />}
                    size={linkedinIcon}
                    variant="ghost"
                    color="#0077b5"
                    _hover={{ background: 'none', color: 'red.500' }}
                  />
                </Link>
              </Flex>
              <Text fontSize={{ base: '14px', md: '16px', lg: '18px' }} fontWeight="500" color="gray.500" mb={2}>
                {member.title}
              </Text>
              <Text fontSize={{ base: '14px', md: '16px', lg: '18px' }} fontWeight="300" color="gray.600" lineHeight="1.8">
                {member.description}
              </Text>
              {index < teamMembers.length - 1 && <Divider mt={6} />}
            </Box>
          ))}
        </Stack>
      </Box>
    </Flex>
  );
};

export default Team;